import { useRef } from 'react'

export default function useThrottle(callBack,limit) {
  const lastTimeRun=useRef(Date.now())
  return function (){
    if(Date.now()-lastTimeRun.current  > limit)
    {
        lastTimeRun.current=Date.now()
        callBack()
    }
  }
}
