import React, { useState } from "react";
import CookieConsent from "react-cookie-consent";
import { handleGtmScriptAdd } from "../../utils/helper";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Svgs from "./Svgs";

export default function CookiePopup() {
  const [cookieConsentStatus, setCookieConsetStatus] = useState({
    functionalPermission: false,
    advertisingPermission: false,
    performancePermission: false,
  });

  const handleDeclineCookie = () => {
    handleGtmScriptAdd(cookieConsentStatus);
    document.cookie = "ww-cookie=true;";
  };

  const handleCookie = (e) => {
    setCookieConsetStatus((prev) => {
      return {
        ...prev,
        [e.target.value]: e.target.checked,
      };
    });
  };

  const cookiePermission = [
    {
      value: "functionalPermission",
      checked: "functionalCookies",
      label: "Functional cookies",
    },
    {
      value: "advertisingPermission",
      checked: "advertisingCookies",
      label: "Advertising cookies",
    },
    {
      value: "performancePermission",
      checked: "performanceCookies",
      label: "Performance cookies",
    },
  ];

  return (
    <CookieConsent
      location="bottom"
      enableDeclineButton
      buttonText="Accept All"
      declineButtonText="Remember my preference"
      buttonWrapperClasses="flex flex-col justify-center h-full"
      buttonClasses="bg-black px-4 py-1 leading-8 font-semibold rounded-md border border-black hover:bg-white hover:text-black text-white transition-all duration-200"
      declineButtonClasses="bg-black px-4 py-1 leading-8 font-semibold rounded-md border border-black hover:bg-white hover:text-black text-white transition-all duration-200"
      cookieName="ww-cookie"
      buttonStyle={{ backgroundColor: "white", color: "black" }}
      declineButtonStyle={{ backgroundColor: "black", color: "white" }}
      onAccept={() =>
        handleGtmScriptAdd({
          functionalPermission: true,
          advertisingPermission: true,
          performancePermission: true,
        })
      }
      onDecline={() => handleDeclineCookie()}
    >
      <div className="space-y-3 p-8">
        <h2 className="text-2xl font-medium">
          Let use know you agree to cookies
        </h2>
        <p className="font-semibold text-base ">
          We use cookies to give you the best online experience. Please let us
          know if you agree to functional, advertising and performance cookies.
          You can update your cookie preferences at any time.
        </p>
        <div className="p-3 flex flex-col gap-2 justify-center items-start">
          <div className="flex gap-1">
            <Svgs icon="Check" fillClass={"w-[18px] fill-white"} />
            Essential cookies
          </div>
          {cookiePermission.map((permission, index) => {
            return (
              <div className="flex gap-1" key={Math.random() + index}>
                <input
                  type="checkbox"
                  name="cookies"
                  value={permission.value}
                  checked={cookieConsentStatus?.[permission.value]}
                  onChange={handleCookie}
                />
                {permission.label}
              </div>
            );
          })}
        </div>
        <div>
          <Link
            className="!text-white text-base underline"
            to={"/cookie-policy"}
          >
            Cookie policy
          </Link>
        </div>
      </div>
    </CookieConsent>
  );
}
