import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Menu, Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  ChatContext,
  UserDetailsContext,
  capitalize,
  classNames,
  cleanLocalStorage,
  getLocalStorageItem,
  getRandomColor,
  searchTabFromName,
  useOutsideClick,
  userData,
} from "../utils/helper";
import { ReactComponent as SettingIcon } from "../assets/images/setting.svg";
import { ReactComponent as SearchIcon } from "../assets/images/search.svg";
import LogoutIcon from "../assets/images/logout.svg";
import CreditIcon from "../assets/images/credit.svg";
import { Link, useHistory } from "react-router-dom";
import Logo from "../assets/images/hexitime-logo.svg";
import ConfirmPopup from "../component/common/ConfirmPopup";
import { Api } from "../api";
import Select, { components } from "react-select";
import { ReactComponent as RequestIcon } from "../assets/images/request-search.svg";
import { ReactComponent as OfferIcon } from "../assets/images/offer-search.svg";
import { ReactComponent as ThreadIcon } from "../assets/images/thread-search.svg";
import Divider from "../component/common/Divider";
import { useAuth } from "oidc-react";
import Svgs from "../component/common/Svgs";
import { DefaultProfileImage } from "../component/common/DefaultProfileImage";
import CookieSetting from "../component/common/Cookie/CookieSetting";
import { getCookieConsentValue } from "react-cookie-consent";

export default function Header() {
  const history = useHistory();
  const userD = userData();
  const { allNetworks } = useContext(UserDetailsContext);
  const [showCookieConsentPage, setShowCookieConsentPage] = useState(false);
  const navigationArr = [
    {
      name: "Home",
      href: "/home",
      current: true,
      icon: (
        <Svgs
          icon="HomeIcon"
          fillClass="fill-hexitime-primary mr-3 h-6 w-6 flex-shrink-0"
        />
      ),
    },
    {
      name: "My Profile",
      href: `/profile/${userD?.user_id}`,
      current: false,
      icon: (
        <Svgs
          icon="ProfileIcon"
          fillClass="fill-hexitime-primary mr-3 h-6 w-6 flex-shrink-0"
        />
      ),
    },
    {
      name: "Scheduled Activities",
      href: "/scheduled-activities",
      current: false,
      icon: (
        <Svgs
          icon="ActivitiesIcon"
          fillClass="fill-hexitime-primary mr-3 h-6 w-6 flex-shrink-0 "
        />
      ),
    },
  ];

  const wrapperRef = useRef(null);

  const {
    profilePicture,
    isAccessible,
    setIsAccessible,
    credits,
    usersData,
    activityCount,
    currentScreenWidth,
  } = useContext(UserDetailsContext);
  const { setShowChat, setFirstVisible } = useContext(ChatContext);

  const [navigation, setNavigation] = useState(navigationArr);
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [showMobileSearch, setShowMobileSearch] = useState(false);
  const [searchLoader, setSearchLoader] = useState(false);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const specificOptionSelect = useRef({
    previousesearchKey: "",
    selected: false,
  });
  const [shuffledColors, setShuffledColors] = useState(getRandomColor());
  const [groupedOptions, setGroupedOptions] = useState([
    {
      label: "Offers",
      options: searchResults?.offers ? searchResults?.offers : [],
      icon: OfferIcon,
    },
    {
      label: "Requests",
      options: searchResults?.requests ? searchResults?.requests : [],
      icon: RequestIcon,
    },
    {
      label: "Threads",
      options: searchResults?.threads ? searchResults?.threads : [],
      icon: ThreadIcon,
    },
    {
      label: "Users",
      options: searchResults?.users ? searchResults?.users : [],
    },
    {
      label: "Networks",
      options: searchResults?.networks ? searchResults?.networks : [],
    },
  ]);
  const { signOut } = useAuth();

  useEffect(() => {
    setShuffledColors(getRandomColor());
  }, []);

  useEffect(() => {
    const currentPagePath = window.location.pathname;

    const currentIndex = navigation.findIndex((item) => item.current === true);

    const pageIndex = navigation.findIndex(
      (item) => item.href === currentPagePath
    );

    if (currentIndex !== pageIndex) {
      setNavigation((navigation) =>
        navigation.map((item, index) => ({
          ...item,
          current: index === pageIndex,
        }))
      );}
  }, [navigation]);

  const name = userD?.user_name?.split(" ");

  useEffect(() => {
    let steps = userD?.is_step;
    const stepKeys = Object.keys(steps || {});

    const allStepsCompleted = stepKeys.every((step) => steps?.[step]);

    if (getLocalStorageItem("token") && allStepsCompleted) {
      setIsAccessible(true);
    } else {
      setIsAccessible(false);
      cleanLocalStorage();
      localStorage.setItem("redirect_url", window.location.pathname);
      history.push("/");
    }
    // eslint-disable-next-line
  }, [history]);

  const handleLogout = async () => {
    try {
      window.location.href = process.env.REACT_APP_BASE_URL;
      signOut();
    } catch (error) {
      console.log("error", error);
    }
    cleanLocalStorage();
    setIsAccessible(false);
  };

  const getSearchResults = () => {
    setSearchLoader(true);

    Api.searchAll(searchKey)
      .then((response) => {
        if (response?.data?.meta?.code === 200) {
          setShowSearchResults(true);
          setNoResults(false);

          const tempData = response?.data?.detail;

          if (tempData?.offers?.length > 0) {
            tempData.offers.forEach((offer) => {
              offer.value = offer.title;
              offer.label = offer.title;
              offer.type = "offer";
              offer.icon = OfferIcon;
            });
          }

          if (tempData?.requests?.length > 0) {
            tempData.requests.forEach((request) => {
              request.value = request.title;
              request.label = request.title;
              request.type = "request";
              request.icon = RequestIcon;
            });
          }

          if (tempData?.threads?.length > 0) {
            tempData.threads.forEach((thread) => {
              thread.value = thread.title;
              thread.label = thread.title;
              thread.type = "thread";
              thread.icon = ThreadIcon;
            });
          }

          if (tempData?.users?.length > 0) {
            tempData.users.forEach((user) => {
              user.value = `${user.user_name?.trim()} - ${user.job_position}`;
              user.label = `${user.user_name?.trim()} - ${user.job_position}`;
              user.type = "user";
            });
          }

          if (tempData?.networks?.length > 0) {
            tempData.networks.forEach((network) => {
              network.value = `${network.name}`;
              network.label = `${network.name}`;
              network.type = "network";
            });
          }

          setSearchResults(tempData);
        } else {
          setNoResults(true);
          setSearchResults([]);
        }
      })
      .finally(() => {
        setSearchLoader(false);
      });
  };

  const handleChange = (e) => {
    setSearchKey(e);
  };

  useEffect(() => {
    if (searchKey.length > 2) {
      getSearchResults();
    } else {
      setSearchResults([]);
      setShowSearchResults(false);
    }

    // eslint-disable-next-line
  }, [searchKey]);

  useOutsideClick(wrapperRef, () => {
    if (showSearchResults) setShowSearchResults(!showSearchResults);
    setSearchKey("");
    setSearchResults([]);
  });

  useEffect(() => {
    const tempOptions = [
      {
        label: "Offers",
        options: searchResults?.offers ? searchResults?.offers : [],
        icon: OfferIcon,
      },
      {
        label: "Requests",
        options: searchResults?.requests ? searchResults?.requests : [],
        icon: RequestIcon,
      },
      {
        label: "Threads",
        options: searchResults?.threads ? searchResults?.threads : [],
        icon: ThreadIcon,
      },
      {
        label: "Users",
        options: searchResults?.users ? searchResults?.users : [],
      },
      {
        label: "Networks",
        options: searchResults?.networks ? searchResults?.networks : [],
      },
    ];

    setGroupedOptions(tempOptions);
  }, [searchResults]);

  const formatGroupLabel = (data) => (
    <div className="flex justify-between capitalize">
      <p className="text-hexitime-primary font-semibold text-sm">
        {data?.label}
      </p>

      {data?.options?.length > 1 && (
        <Link
          to={{
            pathname: "/home/search-results",
            state: { type: data?.label, text: searchKey },
          }}
          className="text-hexitime-primary font-semibold underline text-sm cursor-pointer"
          onClick={() => setShowSearchResults(false)}
        >
          See all
        </Link>
      )}
    </div>
  );

  const Group = (props) => (
    <div>
      <components.Group {...props} />
      <div className="px-[12px] custom-divider">
        <Divider />
      </div>
    </div>
  );

  const Option = (props) => (
    <div>
      <components.Option {...props}>
        <Link
          to={
            props?.data?.type === "offer"
              ? `/profile/offer-detail/${props?.data?.content_id}`
              : props?.data?.type === "request"
              ? `/profile/request-detail/${props?.data?.content_id}`
              : props?.data?.type === "thread"
              ? `/profile/thread-detail/${props?.data?.content_id}`
              : props?.data?.type === "network"
              ? `/network/${props?.data?.id}`
              : `/profile/${props?.data?.user_id}`
          }
          className="first:mt-0 mt-4 flex gap-2 items-center"
          onClick={() => {
            specificOptionSelect.current.selected = true;
            setShowSearchResults(false);
          }}
        >
          {props?.data?.type === "user" || props?.data?.type === "network" ? (
            props?.data?.profile_picture || props?.data?.logo ? (
              <img
                src={
                  props?.data?.type === "user"
                    ? props?.data?.profile_picture
                    : props?.data?.logo
                }
                className="h-6 w-6 rounded-full"
                alt="profile-pic"
              />
            ) : (
              <DefaultProfileImage
                classValue={"h-6 w-6 rounded-full"}
                first_name={props?.data?.user_name?.split(" ")[0]}
                last_name={props?.data?.user_name?.split(" ")[1]}
              ></DefaultProfileImage>
            )
          ) : (
            // eslint-disable-next-line
            <props.data.icon className="h-6 w-6" />
          )}

          <div className="text-hexitime-textColor3 font-semibold text-sm line-clamp-1 max-w-[450px] w-full">
            <span className="text-hexitime-textColor1 ">
              {props?.data?.title
                ? props?.data?.title
                : props?.data?.user_name
                ? props?.data?.user_name
                : props?.data?.name}
            </span>
            {props?.data?.type === "user" && props?.data?.job_position && (
              <span className="text-hexitime-textColor3 font-normal">
                {" "}
                - {props?.data?.job_position}
              </span>
            )}
          </div>
        </Link>
      </components.Option>
    </div>
  );

  useEffect(() => {
    if (!specificOptionSelect.current.selected && selectedOption) {
      specificOptionSelect.current.selected = false;
      const { offers, requests, threads, users, networks } = searchResults;

      let redirectToTab;

      switch (selectedOption.type) {
        case "offer":
          redirectToTab = offers?.[0];
          break;
        case "request":
          redirectToTab = requests?.[0];
          break;
        case "thread":
          redirectToTab = threads?.[0];
          break;
        case "user":
          redirectToTab = users?.[0];
          break;
        case "network":
          redirectToTab = networks?.[0];
          break;
        default:
          redirectToTab = offers?.[0];
          break;
      }

      history.push({
        pathname: "/home/search-results",
        state: {
          type: searchTabFromName(redirectToTab?.type),
          text: specificOptionSelect.current.previousesearchKey,
        },
      });
      setShowSearchResults(false);
    } else if (specificOptionSelect.current.selected && selectedOption) {
      if (selectedOption?.type === "thread") {
        history.push(`/profile/thread-detail/${selectedOption?.content_id}`);
      }

      if (selectedOption?.type === "offer") {
        history.push(`/profile/offer-detail/${selectedOption?.content_id}`);
      }

      if (selectedOption?.type === "request") {
        history.push(`/profile/request-detail/${selectedOption?.content_id}`);
      }

      if (selectedOption?.type === "user") {
        history.push(`/profile/${selectedOption?.user_id}`);
      }

      if (selectedOption?.type === "network") {
        history.push(`/network/${selectedOption?.id}`);
      }
    }
    // eslint-disable-next-line
  }, [selectedOption]);

  const searchResult = (props) => {
    if (!noResults) {
      if (searchKey?.length > 2) {
        setShowSearchResults(false);

        const optionWithValidType = props?.options?.find((option) =>
          option?.options?.some((subOption) => subOption?.type)
        );

        const responseType = optionWithValidType?.options[0]?.type || null;

        const tempResponseType = searchTabFromName(responseType);
        const data = {
          type: tempResponseType,
          text: searchKey,
        };

        history.push("/home/search-results", data);
      }
    }
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator
        {...props}
        innerProps={{
          ...props.innerProps,
          onMouseDown: (e) => {
            e.stopPropagation();
            e.preventDefault();
          },
          onTouchEnd: (e) => {
            e.stopPropagation();
            e.preventDefault();
          },
        }}
      >
        <button
          type="button"
          className="!relative !-ml-px !inline-flex !items-center !gap-x-1.5 !rounded-r-md !px-4 !py-3 !text-sm !font-semibold !bg-hexitime-primary !min-w-[50px]"
          onClick={() => searchResult(props)}
        >
          <SearchIcon className="!h-5 !w-5 !text-white" />
        </button>
      </components.DropdownIndicator>
    );
  };

  useEffect(() => {
    const emptyArrays = groupedOptions?.filter(
      (item) => !item?.options || item?.options?.length === 0
    );

    if (emptyArrays?.length === groupedOptions?.length) {
      setNoResults(true);
    } else {
      setNoResults(false);
    }
    // eslint-disable-next-line
  }, [searchResults]);

  useEffect(() => {
    if (currentScreenWidth > 640) {
      setShowMobileSearch(false);
    }
  }, [currentScreenWidth]);

  const logo = localStorage.getItem("logo");
  return (
    <>
      {/* When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars */}
      <Popover
        as="header"
        className={({ open }) =>
          classNames(
            open ? "!fixed !inset-0 !z-40 !overflow-y-auto" : "",
            `!bg-white !sticky !top-0 !overflow-y-visible !z-20 !shadow-[0_4px_15px_rgba(33,48,58,0.08)] ${
              !isAccessible ? "!min-h-[78px] !flex !items-center" : ""
            }`
          )
        }
      >
        {({ open }) => (
          <>
            <div className="px-4 sm:px-6 md:px-[30px] w-full min-h-[64px] sm:min-h-full ">
              {showMobileSearch ? (
                <div className="relative">
                  <div className="w-full absolute top-2.5 flex items-center gap-2 bg-white sm:hidden z-50">
                    <Select
                      options={groupedOptions}
                      components={{
                        Option,
                        Group,
                        DropdownIndicator,
                        LoadingIndicator: () => null,
                      }}
                      formatGroupLabel={formatGroupLabel}
                      value={null}
                      onChange={setSelectedOption}
                      onInputChange={(e) => {
                        specificOptionSelect.current.previousesearchKey =
                          searchKey;
                        handleChange(e);
                      }}
                      menuIsOpen={searchKey?.length > 2}
                      className="global-search-main"
                      classNamePrefix="global-search"
                      isLoading={searchLoader}
                      placeholder="Search for offers, requests, threads or users :)"
                      closeMenuOnSelect={true}
                      autoFocus={true}
                    />
                    <XMarkIcon
                      className="block h-6 w-6 cursor-pointer text-hexitime-textColor2 hover:text-hexitime-textColor1"
                      aria-hidden="true"
                      onClick={() => setShowMobileSearch(false)}
                    />
                  </div>
                </div>
              ) : (
                <div className="!relative !flex !justify-between lg:!gap-8 xl:!grid xl:!grid-cols-12">
                  <div className="!flex lg:!static xl:!col-span-2 !py-3 sm:!py-0">
                    <div className="!flex !flex-shrink-0 !items-center">
                      <a
                        href="https://warwickinnovationdistrict.com/"
                        target="_blank"
                      >
                        <img
                          className="!block md:!h-12 !h-10 !w-auto"
                          src={logo != "null" ? logo : Logo}
                          alt="Warwick Innovation District"
                        />
                      </a>
                    </div>
                  </div>

                  <div
                    className="!hidden sm:!block !min-w-0 !flex-1 md:!px-8 lg:!px-0 xl:!col-span-6 !relative"
                    ref={wrapperRef}
                  >
                    {isAccessible && !showMobileSearch && (
                      <div className="!flex !items-center !px-6 sm:!py-3 !py-2 md:!mx-auto lg:!mx-0 xl:!px-0 md:!max-w-3xl lg:!max-w-none">
                        <div className="!w-full !relative">
                          <Select
                            options={groupedOptions}
                            components={{
                              Option,
                              Group,
                              DropdownIndicator,
                              LoadingIndicator: () => null,
                            }}
                            formatGroupLabel={formatGroupLabel}
                            value={null}
                            onChange={setSelectedOption}
                            onInputChange={(e) => {
                              specificOptionSelect.current.previousesearchKey =
                                searchKey;
                              handleChange(e);
                            }}
                            menuIsOpen={searchKey?.length > 2}
                            className="global-search-main"
                            classNamePrefix="global-search"
                            isLoading={searchLoader}
                            placeholder="Search for offers, requests, threads or users :)"
                            closeMenuOnSelect={true}
                          />
                        </div>

                        <Link to="/updates-alerts">
                          <Svgs
                            icon="BellIcon"
                            fillClass="!block !h-6 !w-6 !ml-4 !cursor-pointer xl:!hidden md:!block sm:!hidden !fill-hexitime-primary"
                          />
                        </Link>
                      </div>
                    )}
                  </div>

                  {isAccessible ? (
                    <>
                      <div className="!flex !items-center md:!absolute md:!inset-y-0 md:!right-0 lg:!hidden !py-3 sm:!py-0">
                        <SearchIcon
                          className="!block !h-6 !w-6 !mr-4 !cursor-pointer sm:!hidden !text-hexitime-primary"
                          onClick={() => setShowMobileSearch(true)}
                        />

                        <Link to="/updates-alerts">
                          <Svgs
                            icon="BellIcon"
                            fillClass="!block !h-6 !w-6 !mr-4 !cursor-pointer md:!hidden fill-"
                          />
                        </Link>
                        {/* Mobile menu button */}
                        <Popover.Button className="!-mx-2 !inline-flex !items-center !justify-center !rounded-md !p-2 !text-hexitime-textColor3 hover:!text-hexitime-textColor2 focus:!outline-none">
                          <span className="!sr-only">Open menu</span>
                          {open ? (
                            <XMarkIcon
                              className="!block !h-6 !w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <Bars3Icon
                              className="!block !h-6 !w-6"
                              aria-hidden="true"
                            />
                          )}
                        </Popover.Button>
                      </div>

                      <div className="!hidden lg:!flex lg:!items-center lg:!justify-end xl:!col-span-4">
                        {/* Profile dropdown */}
                         {getCookieConsentValue("ww-cookie") && (
                          <div className="bg-hexitime-primary p-2 rounded-xl  hover:bg-hexitime-darkPrimary">
                            <button
                              className="text-base cursor-pointer font-[400] !text-white"
                              onClick={() => setShowCookieConsentPage(true)}
                              disabled={!getCookieConsentValue("ww-cookie")}
                            >
                              Cookie Preference
                            </button>
                          </div>
                        )} 
                        <Menu
                          as="div"
                          className="!relative !ml-5 !flex-shrink-0"
                        >
                          <div>
                            <span className="!sr-only">Open user menu</span>

                            <div className="!flex !relative !items-center">
                              <Link to={`/profile/${userD?.user_id}`}>
                                {profilePicture != "undefined" &&
                                profilePicture !== "null" &&
                                profilePicture ? (
                                  <img
                                    className="!h-9 !w-9 !rounded-full !self-center !object-cover !object-center"
                                    src={profilePicture}
                                    alt=""
                                  />
                                ) : (
                                  <div
                                    className="!self-center !flex !justify-center !items-center !w-[50px] !h-[50px] !rounded-full"
                                    style={{
                                      background: shuffledColors?.bgColor,
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: shuffledColors?.textColor,
                                      }}
                                    >
                                      {name && name[0]?.charAt(0)}
                                    </span>
                                    <span
                                      style={{
                                        color: shuffledColors?.textColor,
                                      }}
                                    >
                                      {name && name[1]?.charAt(0)}
                                    </span>
                                  </div>
                                )}
                              </Link>

                              <Menu.Button className="!flex !rounded-full !bg-white focus:!outline-none">
                                <div className="!text-start !self-center !ml-3 !hidden md:!block">
                                  <p className="!text-hexitime-textColor1 !text-[18px] !m-0 !line-clamp-1 !break-all !max-w-[200px]">
                                    {capitalize(
                                      usersData?.user_name || userD?.user_name
                                    )}
                                  </p>
                                </div>

                                <ChevronDownIcon className="!ml-2 !w-[25px] !text-hexitime-textColor3" />
                              </Menu.Button>
                            </div>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="!absolute !right-0 !z-10 !mt-2 !w-48 !origin-top-right !rounded-md !bg-white !shadow-[0_7px_20px_rgba(33,48,58,0.03)] !ring-1 !ring-black !ring-opacity-5 focus:!outline-none !pb-3">
                              <div className="!bg-hexitime-lightPrimary !p-3 !flex !items-center !flex-col">
                                <img
                                  src={CreditIcon}
                                  alt="credit-icon"
                                  className="!h-[35px] !w-[35px]"
                                />
                                <p className="!mt-1 !text-base !text-hexitime-textColor1 !break-words">
                                  <span className="!break-all">
                                    {credits?.available}{" "}
                                  </span>
                                  <span className="!text-hexitime-textColor2">
                                    {credits?.available > 1
                                      ? "credits"
                                      : "credit"}{" "}
                                    available
                                  </span>
                                </p>
                              </div>

                              <div className="!pt-1 !px-3">
                                <Menu.Item>
                                  {({ active }) => (
                                    <Link
                                      to="/settings"
                                      className={classNames(
                                        active ? "!bg-white" : "",
                                        "!inline-flex !items-center !gap-x-1.5 !py-3 !text-sm !text-hexitime-textColor2"
                                      )}
                                    >
                                      <SettingIcon className="!-ml-0.5 !h-5 !w-5 !text-hexitime-primary" />
                                      Settings
                                    </Link>
                                  )}
                                </Menu.Item>

                                <div className="!relative">
                                  <div
                                    className="!absolute !inset-0 !flex !items-center"
                                    aria-hidden="true"
                                  >
                                    <div className="!w-full !border-t !border-hexitime-textColor4" />
                                  </div>
                                </div>

                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      className={classNames(
                                        active ? "!bg-white" : "",
                                        "!inline-flex !items-center !gap-x-1.5 !py-3 !text-sm !text-hexitime-textColor2"
                                      )}
                                      onClick={() => setOpenConfirmPopup(true)}
                                    >
                                      <img
                                        src={LogoutIcon}
                                        className="!-ml-0.5 !h-5 !w-5"
                                        alt="Sign Out"
                                      />
                                      Sign Out
                                    </button>
                                  )}
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </>
                  ) : (
                    <div className="flex sm:gap-x-4 gap-x-2 items-center justify-end xl:col-span-4">
                      <Link
                        to="/"
                        className="font-semibold sm:text-base text-sm text-hexitime-primary hover:text-hexitime-darkPrimary"
                      >
                        Sign in
                      </Link>
                      <Link
                        to="/register"
                        className="text-white sm:text-base text-sm font-semibold bg-hexitime-primary hover:bg-hexitime-darkPrimary px-3 py-2 rounded-md"
                      >
                        Sign Up
                      </Link>
                    </div>
                  )}
                </div>
              )}
            </div>

            <Popover.Panel
              as="nav"
              className="lg:hidden absolute w-full bg-white z-[999]"
              aria-label="Global"
            >
              <div className="mr-auto max-w-3xl space-y-1 px-2 pb-3 pt-2 sm:px-4 md:px-5">
                {navigation?.map((item) => (
                  <Link
                    to={item.href}
                    key={item.name}
                    aria-current={item.current ? "page" : undefined}
                    className={classNames(
                      item.current
                        ? "text-hexitime-primary"
                        : "text-hexitime-textColor2 hover:text-hexitime-primary",
                      "flex items-center gap-x-2 rounded-md py-2 px-3 text-base font-medium bg-white"
                    )}
                  >
                    {item?.icon}
                    {item?.name}
                    {item?.name === "Scheduled Activities" &&
                      parseInt(activityCount) > 0 && (
                        <p className="rounded-full h-6 w-6 bg-hexitime-primaryRed p-1 text-[0.75rem] text-white shadow-sm focus-firstVisible:outline flex items-center justify-center ml-2">
                          {parseInt(activityCount) > 99 ? "99+" : activityCount}
                        </p>
                      )}
                  </Link>
                ))}
              </div>
              <div className="border-t border-hexitime-textColor4 pb-3 pt-4 px-4 sm:px-8">
                <p className="text-hexitime-primary">Networks</p>
                {allNetworks?.map((network, i) => (
                  <Link
                    to={`/network/${network?.id}`}
                    key={i}
                    className="flex items-center mt-3 cursor-pointer"
                    onClick={() => {
                      setShowChat(false);
                      setFirstVisible(null);
                    }}
                  >
                    <img
                      src={network?.logo}
                      alt="sidebar_icon"
                      className={`mr-3 h-8 w-8 flex-shrink-0 text-hexitime-primary rounded-full`}
                    />
                    <p className="text-hexitime-textColor2 text-md">
                      {network?.name}
                    </p>
                  </Link>
                ))}
             </div>
              <div className="border-t border-hexitime-textColor4 pb-3 pt-4">
                <div className="mr-auto flex max-w-3xl items-center px-4 sm:px-8">
                  <div className="flex-shrink-0">
                    {profilePicture && profilePicture !== "null" ? (
                      <img
                        className="h-10 w-10 rounded-full object-cover object-center"
                        src={profilePicture}
                        alt=""
                      />
                    ) : (
                      <div
                        className="self-center flex justify-center items-center w-[50px] h-[50px] rounded-full"
                        style={{
                          background: shuffledColors?.bgColor,
                        }}
                      >
                        <span
                          style={{
                            color: shuffledColors?.textColor,
                          }}
                        >
                          {name && name[0]?.charAt(0)}
                        </span>
                        <span
                          style={{
                            color: shuffledColors?.textColor,
                          }}
                        >
                          {name && name[1]?.charAt(0)}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-hexitime-textColor1">
                      {userD?.user_name}
                    </div>
                  </div>
                </div>
                <div className="mr-auto mt-3 max-w-3xl space-y-1 px-2 sm:px-6">
                  <Link
                    to="/settings"
                    className="flex items-center gap-x-1.5 py-2 px-3 text-base text-hexitime-textColor2 hover:text-hexitime-primary"
                  >
                    <SettingIcon className="-ml-0.5 h-5 w-5 text-hexitime-primary" />
                    Settings
                  </Link>

                  <button
                    className="flex items-center gap-x-1.5 py-2 px-3 text-base text-hexitime-textColor2 hover:text-hexitime-primary"
                    onClick={() => setOpenConfirmPopup(true)}
                  >
                    <img
                      src={LogoutIcon}
                      className="-ml-0.5 h-5 w-5"
                      alt="signout"
                    />
                    Sign Out
                  </button>
                </div>
                 <div className="mr-auto flex max-w-3xl items-center px-4 sm:px-8 py-2 pb-4">
                  {getCookieConsentValue("ww-cookie") && (
                    <div >
                      <button
                        className="text-lg underline cursor-pointer font-[400] text-hexitime-primary hover:text-hexitime-darkPrimary"
                        onClick={() => setShowCookieConsentPage(true)}
                        disabled={!getCookieConsentValue("ww-cookie")}
                      >
                        Cookie Preference
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </Popover.Panel>
          </>
        )}
      </Popover>

      {openConfirmPopup && (
        <ConfirmPopup
          open={openConfirmPopup}
          setOpen={setOpenConfirmPopup}
          message={"Are you sure you want to logout?"}
          setAccepted={() => handleLogout()}
        />
      )}
      {showCookieConsentPage && (
        <CookieSetting
          open={showCookieConsentPage}
          setOpen={setShowCookieConsentPage}
        ></CookieSetting>
      )}
    </>
  );
}
